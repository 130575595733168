import React from 'react';
import styled from 'styled-components';
import { Container, Col, Row } from 'reactstrap';
import Layout from '../layouts';
import SEO from '../components/seo';
import BG from '../components/bg';
import Contact from '../components/contact';

const StyledAbout = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.buttonBorder};
  background-color: ${({ theme }) => theme.colors.blackWhite};
  padding: 0rem 0rem 6rem 0;
  margin-top: -110px;
`;

const Title = styled.h1`
  margin-bottom: 2rem;
  font-size: 42px;

  pointer-events: none;
  white-space: wrap;
  overflow-wrap: normal;
  max-width: 1200px;
  @media (max-width: 960px) {
    font-size: 2.5rem;
  }
`;

const Text = styled.p`
  font-size: 1.2rem;

  @media (max-width: 960px) {
    font-size: 1rem;
  }
`;

const Image = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  margin-bottom: 2rem;
`;

const Link = styled.a`
  display: block;
  margin-top: 1.2rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.whiteBlack};
  text-decoration: none;
  z-index: 50;
  &:hover {
    text-decoration: underline;
  }
`;

const AtrakcjePage = (props) => {
  return (
    <Layout path={props.location.pathname}>
      <BG />
      <SEO title="Atrakcje" path={props.location.pathname} />
      <StyledAbout>
        <Container style={{ paddingTop: 140 }}>
          <Title style={{marginBottom: "3rem"}} >Atrakcje turystyczne w okolicy</Title>

          <Row style={{marginBottom: "1.5rem"}}>
            <Col md={6}>
              <Text>
                <strong>Browar Żywiecki:</strong> Zwiedzanie oraz degustacja piwa. Najlepsze piwo wśród piwoszy „Żywiec” - mogą Państwo spróbować i przekonać się o jego tajemnej mocy oraz zobaczyć najnowocześniejszą produkcję i historię piwa w Polsce.
              </Text>
            </Col>
            <Col md={6}>
              <Image src="https://muzeumbrowaru.pl/wp-content/uploads/sites/5/2023/06/b4edfae08f_1_11.jpg" alt="Browar Żywiecki" />
            </Col>
          </Row>

          <Row style={{marginBottom: "1.5rem"}} >
            <Col md={6}>
              <Image src="https://www.dreamapart.pl/storage/app/media/blog/jaskinie-w-szczyrku.jpg" alt="Jaskinie" />
            </Col>
            <Col md={6}>
              <Text>
                <strong>Jaskinie:</strong> Niezapomniana przygoda w poznawaniu jaskiń, które umożliwiają poznanie geologii i morfologii Beskidu Śląskiego. Odkryj Jaskinię Chłodną, Jaskinię Pod Balkonem oraz Malinowskie Skały z ich wspaniałymi widokami.
              </Text>
            </Col>
          </Row>

          <Row style={{marginBottom: "1.5rem"}} >
            <Col md={6}>
              <Text>
                <strong>Wyciąg w dolinie:</strong> Atrakcje dla narciarzy tylko 500 m od obiektu. Dostępne są stok narciarski, szkółka dla dzieci, wyciągi o różnym stopniu trudności oraz tor snow tubing i szkolenia narciarskie dla dzieci od 3 roku życia.
                <Link href="https://www.dimbo.pl/polska/zimnik/cennik/" target="_blank" rel="noopener noreferrer">Dowiedz się więcej</Link>

              </Text>
            </Col>
            <Col md={6}>
              <Image src="https://www.dimbo.pl/wp-content/uploads/2015/05/dimbo-logo-www.svg" alt="Wyciąg w dolinie" />
            </Col>
          </Row>

          <Row style={{marginBottom: "1.5rem"}} >
            <Col md={6}>
              <Image src="https://www.szlaki.net.pl/foto/duze/12/1196zolty1002_2.jpg" alt="Szlaki turystyczne" />
            </Col>
            <Col md={6}>
              <Text>
                <strong>Szlaki:</strong><br></br> 
                <strong>Niebieski</strong> - Lipowa Ostre (539 m) - Hala Jaskowa (950 m) - Skrzyczne (1257 m)<br></br>
                <strong>Żółty</strong> - Lipowa Ostre (539 m) - Dolina Zimnika - Łukaszne - Kościelec (1022 m) -  Malinowska Skała (1152 m)<br></br>
                <strong>Zielony</strong> - Ostre (539 m) - Hala Ostre - Muronka (1017 m) - Magurka Radziechowska (1091 m)
              </Text>
            </Col>
          </Row>

          <Row style={{marginBottom: "1.5rem"}}>
            <Col md={6}>
              <Text>
                <strong>Zagroda Małych Cudów</strong> <br></br>
                
                Zapraszamy do naszej przytulnej zagrody, gdzie czekają na Ciebie alpaki i konie! Spotkanie z tymi uroczymi zwierzętami to doskonała okazja do relaksu i bliskiego kontaktu z naturą. Alpaki urzekną Cię swoją łagodnością, a nasze konie są gotowe do przejażdżek zarówno dla dzieci, jak i dorosłych. Organizujemy również warsztaty i zajęcia edukacyjne, które pozwalają lepiej poznać te fascynujące zwierzęta. Przyjdź i przeżyj wyjątkowe chwile w otoczeniu natury!
                <Link href="https://www.facebook.com/p/Zagroda-Małych-Cudów-100083241456899/?_rdr" target="_blank" rel="noopener noreferrer">Dowiedz się więcej</Link>
              </Text>
            </Col>
            <Col md={6}>
              <Image src="https://scontent.fktw5-1.fna.fbcdn.net/v/t39.30808-6/291562832_111117181650478_7098470606271126345_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=nW6whBrgMs8Q7kNvgGLjVPc&_nc_zt=23&_nc_ht=scontent.fktw5-1.fna&_nc_gid=AzJv8NaAFiNGFHpXb3R5kXW&oh=00_AYDp-CuXr2JcuvsW5c8zerpyeQlKHsfcE6zeKB5iNhpUwQ&oe=67613625" alt="zagroda malych cudow" />
            </Col>
          </Row>

          <Row style={{marginBottom: "1.5rem"}}>
            <Col md={6}>
              <Image src="https://kam-rock.pl/icon-poster.png" alt="Wypożyczalnia rowerów elektrycznych" style={{width: "75%"}}/>
            </Col>
            <Col md={6}>
              <Text>
                <strong>Wypożyczalnia rowerów elektrycznych</strong><br></br> 
                Odkryj piękno okolicy dzięki wynajmowi rowerów elektrycznych! Nasze nowoczesne e-rowery pozwalają na wygodne i ekologiczne zwiedzanie nawet najbardziej wymagających tras. Idealne dla rodzin, par oraz osób szukających aktywnego wypoczynku w malowniczym otoczeniu Beskidu Śląskiego. Rowerami można poruszać się zarówno po górskich szlakach, jak i po spokojnych ścieżkach rowerowych, ciesząc się niezapomnianymi widokami. Wynajem obejmuje kaski i krótkie szkolenie z obsługi.
                <Link href="https://kam-rock.pl" target="_blank" rel="noopener noreferrer">Dowiedz się więcej</Link>
              </Text>
            </Col>
          </Row>


        </Container>
      </StyledAbout>
      <Contact />
    </Layout>
  );
};

export default AtrakcjePage;
